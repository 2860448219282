<template>
  <div class="sign">
   <span>
   <p>修改报价</p>
     <div class="Modify" @click="returnSign">返回</div>
   </span>
    <el-form :inline="true" :label-position="labelPosition" :model="formInline" class="demo-form-inline"
             label-width="100px" :rules="rules" ref="ruleForm">
      <div style="display: flex;flex-direction: column">
<!--        <el-form-item label="工程项编号：">-->
<!--          <el-input v-model="formInline.projectCode" class="input" placeholder="请输入您的工程项编号"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="工程项名称：">-->
<!--          <el-input v-model="formInline.projectName" class="input" placeholder="请输入您的工程项名称"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="供应商名称：">-->
<!--          <el-input v-model="formInline.supplyName" class="input" placeholder="请输入您的供应商名称"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="报价总价(元)：" label-width="80">
          <el-input-number v-model="formInline.price" :precision="2" :step="0.1" class="input"
                           placeholder="请输入您的报价总价"></el-input-number>
        </el-form-item>
        <el-form-item label="税率（%）：">
          <el-input-number v-model="formInline.taxRate" :min="1" :max="100" class="input"
                           placeholder="请输入您的税率"></el-input-number>
        </el-form-item>
<!--        <el-form-item label="招标人编号：">-->
<!--          <el-input v-model="formInline.callForBidsCode" class="input" placeholder="请输入您的招标人编号"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="招标人：">-->
<!--          <el-input v-model="formInline.memberName" class="input" placeholder="请输入您的招标人"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="报名日期：">-->
<!--          <el-date-picker-->
<!--              v-model="value1"-->
<!--              class="input"-->
<!--              placeholder="选择日期时间"-->
<!--              type="datetime">-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->
<!--        action='http://112.15.94.215:8088/seeyon/rest/euAttachment'-->
        <el-form-item label="报价附件：">
          <el-upload
            accept=".jpg,.png,.bmp,.txt,.doc,.wps,.docx,.xls,.xlsx,.pdf,.rar,.zip"
              :headers="headers"
              :on-preview="handlePictureCardPreview"
              action='https://www.zjhy.cc/api/seeyon/rest/euAttachment'
              list-type='*'
              multiple
              :limit="10"
              :file-list="fileList"
              :on-change="feiladd"
              :on-remove="fileDel"
              :auto-upload="false"
              :data="fileData"
              ref="upload">
<!--            <i class="el-icon-plus"></i>-->
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img :src="dialogImageUrl" alt="" width="100%">
          </el-dialog>
        </el-form-item>
      </div>
      <div>
        <div style="display: flex;flex-direction: column;">
<!--          <el-form-item label="供应商编号：">-->
<!--            <el-input v-model="formInline.supplyCode" class="input" placeholder="请输入您的供应商编号"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="招标编号：">-->
<!--            <el-input v-model="formInline.memberCode" class="input" placeholder="请输入您的招标编号"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item label="发票类型：">
<!--            <el-select v-model="formInline.invoiceType" class="input" placeholder="请选择发票类型">-->
<!--              <el-option value="增值税专用发票"></el-option>-->
<!--              <el-option value="增值税普通发票"></el-option>-->
<!--            </el-select>-->
            <el-select v-model="formInline.invoiceType" class="input" placeholder="请选择发票类型">
              <el-option
                v-for="(item, index) in enums"
                :key="index"
                :value="item.id"
                :label="item.text">
              </el-option>
            </el-select>
          </el-form-item>

<!--          <el-form-item label="企业ID：">-->
<!--            <el-input v-model="formInline.unitCode" class="input" placeholder="请输入您的企业ID"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="报价状态：">-->
<!--            <el-select v-model="formInline.state" class="input" placeholder="请选择报价状态">-->
<!--              <el-option label="进行中" value="进行中"></el-option>-->
<!--              <el-option label="已截止" value="已截止"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="截至日期：">-->
<!--            <el-date-picker-->
<!--                v-model="value2"-->
<!--                class="input"-->
<!--                placeholder="选择日期时间"-->
<!--                type="datetime">-->
<!--            </el-date-picker>-->
<!--          </el-form-item>-->
        </div>
      </div>
    </el-form>
    <div class="updateRed" @click="updateSignBut()">提交</div>
  </div>
</template>

<script>
import { fileDel, getType, updatePrice } from '@/api/api'
import axios from 'axios'
import { formatDate } from '@/common/date'

export default {
  name: 'UpdataSign',
  props: {
    updateSign: {
      type: Boolean
    },
    bgSign: {
      type: Boolean
    },
    id: String
  },
  data () {
    return {
      fileDelList: [],
      value1: '',
      value2: '',
      updateSignS: this.updateSign,
      bgSignS: this.bgSign,
      formInline: {
        projectCode: '',
        projectName: '',
        invoiceType: '',
        taxRate: '',
        unitCode: '',
        supplyCode: '',
        supplyName: '',
        callForBidsCode: '',
        memberCode: '',
        memberName: '',
        createDate: '',
        price: '',
        state: '进行中'
      },
      labelPosition: 'right',
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      fileData: {
        reference: '',
        subReference: ''
      },
      enums: [],
      rules: {
        price: [
          { required: true, message: '请输入您的报价总价', trigger: 'blur' }
        ],
        taxRate: [
          { required: true, message: '请输入您的税率', trigger: 'blur' }
        ],
        invoiceType: [
          { required: true, message: '请选择发票类型', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    headers () {
      const token = localStorage.getItem('token')
      return {
        token: token
      }
    }
  },
  created () {
    if (this.id !== null && this.id !== '') {
      // 获取发票类型
      getType({
        method: 'enums',
        proCode: 'eu_ticket_type'
      }, res => {
        this.enums = res.data.data
      })
      // 查询记录
      this.queryList()
    } else {
      return null
    }
  },
  methods: {
    feiladd (file, fileList) {
      // 文件类型过滤
      const fileType = file.name.replace(/.+\./, '')
      if (['jpg', 'png', 'bmp', 'txt', 'doc', 'wps', 'docx', 'xls', 'xlsx', 'pdf', 'rar', 'zip'].indexOf(fileType.toLowerCase()) === -1) {
        this.$msgbox({
          title: '',
          message: '文件格式错误，请重新上传。',
          type: 'warning'
        })
        fileList.splice(-1)
      } else {
        // 文件大小过滤
        this.fileSize = Number(file.size / 1024 / 1024)
        if (this.fileSize > 10) {
          this.$msgbox({
            title: '',
            message: '文件大小不能超过10MB，请重新上传。',
            type: 'warning'
          })
          fileList.splice(-1)
        }
        this.fileList = fileList.length
      }
    },
    fileDel (file, fileList) {
      this.formInline.priceAtts.map((item, index, arr) => {
        if (item.fileName === file.name) {
          this.fileDelList.push(file.name)
        }
      })
    },
    returnSign () {
      this.$emit('update', this.updateSignS = false)
      this.$emit('update', this.bgSignS = true)
    },
    handleRemove (file, fileList) {
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    queryList () {
      updatePrice({
        method: 'priceById',
        id: this.id
      }, res => {
        if (res.data.success === false) {
          this.$message.error(res.data.message)
          return
        }
        this.formInline = res.data.data
        // 循环将附件添加
        this.fileList = [] // 循环前将数据清空
        this.formInline.priceAtts.map((item, index, arr) => {
          const temp = {
            name: item.fileName,
            url: item.fileUrls
          }
          this.fileList.push(temp)
        })
      })
    },
    updateSignBut () {
      // console.log(this.fileList)
      const formName = 'ruleForm'
      this.$refs[formName].validate((valid) => {
        if (valid) {
        } else {
          return ''
        }
      })
      if (this.formInline.invoiceType === '') {
        this.$message.error('请选择发票类型')
        return
      } else if (this.fileList.length === 0) {
        this.$message.error('请上传附件')
        return
      }
      axios.defaults.headers.token = localStorage.getItem('token')
      updatePrice({
        method: 'updatePrice',
        // formCode: 'price',
        data: [
          {
            unitId: localStorage.getItem('unitId'),
            tenderInfoId: localStorage.getItem('signId'),
            taxRate: this.formInline.taxRate.toString(),
            price: this.formInline.price.toString(),
            priceAccessory: this.fileList,
            id: this.id,
            invoiceType: this.formInline.invoiceType
          }
        ]
      }, res => {
        if (res.data.success === false) {
          this.$message.error(res.data.message)
          return
        }
        this.$message({
          showClose: true,
          message: '提交成功',
          duration: 5000,
          type: 'success'
        })
        this.fileData.reference = res.data.data.reference
        this.fileData.subReference = res.data.data.subReference
        this.$refs.upload.submit()// 文件上传

        // 删除图片  点击删除时会将文件名保存到fileDelList，点击提交修改时才会删除图片
        this.fileDelList.map(item => {
          this.formInline.priceAtts.map(itemTwo => {
            if (item === itemTwo.fileName) {
              fileDel({
                method: 'delAttBy',
                ascId: itemTwo.ascId
              }, itemThree => {
              })
            }
          })
        })
        this.returnSign()// 返回上一级
      })
      // } else {
      //   this.$message.error('请完成表单后提交报名！')
      // }
    },
    formatDate3 (time) {
      const date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    }
  }
}
</script>

<style scoped>
.sign {
  width: 100%;
  height: 800px;
  /*background-color: #7ac23c;*/
  display: flex;
  flex-direction: column;
}

.sign > span {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
}

.Modify {
  width: 50px;
  height: 24px;
  border: 1px solid #10539B;
  border-radius: 2px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #10539B;
  cursor: pointer;
}

.demo-form-inline {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.input {
  width: 358px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.updateRed {
  cursor: pointer;
  width: 200px;
  height: 50px;
  background: #0D5091;
  border-radius: 4px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 50px;
  text-align: center;
  margin:100px auto 50px;
}
</style>
