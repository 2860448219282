<template>
  <div id="complaint">
    <div style="text-align: right; margin-bottom: 10px">
      <el-button type="primary" @click="dialogVisible = true" v-if="addStatue">我要申诉</el-button>
    </div>
    <!--    主题字体-->
<!--    <div class="complaint_title">-->
<!--      <div class="title_theme">-->
<!--        <p>发起时间</p>-->
<!--        <p>申诉主题</p>-->
<!--        <p>申述内容</p>-->
<!--      </div>-->
<!--      <div class="title_reply">-->
<!--      <p>回复内容</p>-->
<!--      </div>-->
<!--    </div>-->
    <!--    内容-->
    <div class="content">
      <div>
<!--        <div class="content_msg" v-for="(item,index) in msg" :key="index">-->
<!--          <div class="text_msg">-->
<!--            <p>{{ item.entryTime}}</p>-->
<!--            <p>{{ item.appealTheme}}</p>-->
<!--            <p>{{ item. pleadDetails}}</p>-->
<!--          </div>-->
<!--&lt;!&ndash;          <div class="text_msg">&ndash;&gt;-->
<!--&lt;!&ndash;            <p>{{ item.entryTime}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            <p>{{ item.appealTheme}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="text_msg1">&ndash;&gt;-->
<!--&lt;!&ndash;            <p>{{ item. pleadDetails}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="content_msg1">&ndash;&gt;-->
<!--&lt;!&ndash;            <p>{{ item.replyContent }}</p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
        <el-table
          :data="msg"
          style="width: 100%">
          <el-table-column
            prop="entryTime"
            label="发起时间"
            width="180">
          </el-table-column>
          <el-table-column
            prop="appealTheme"
            label="申诉主题"
            width="180">
          </el-table-column>
          <el-table-column
            prop="pleadDetails"
            label="申述内容"
            width="280">
          </el-table-column>
          <el-table-column
            prop="replyContent"
            label="回复内容"
            width="280">
          </el-table-column>
        </el-table>
        <div class="page" v-if="msg.length != 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :total="total"
            background
            layout="prev, pager, next">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="申述"
      :visible.sync="dialogVisible"
      width="30%">
      <div class="appeal-container">
        <div>
          <el-form ref="form" :model="form" label-width="80">
            <el-form-item label="申诉主题">
              <el-input v-model="form.appealTheme" placeholder="请输入申诉主题" maxlength="10"></el-input>
            </el-form-item>
            <el-form-item label="申诉内容" class="appeal-container-content">
              <el-input type="textarea" v-model="form.pleadDetails" placeholder="请输入申诉内容" maxlength="150" show-word-limit></el-input>
            </el-form-item>
          </el-form>
<!--          <div class="submit-button">-->
<!--            <el-button type="primary" @click="appealClick">提交申诉</el-button>-->
<!--          </div>-->
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="appealClick">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ComPlaint, appealIn, getBlacklistByApi } from '@/api/api'

export default {
  name: 'complaint',
  props: {},
  data () {
    return {
      form: {
        appealTheme: '',
        content: ''
      },
      msg: [],
      page: 1,
      size: 10,
      total: 0,
      value: false,
      dialogVisible: false,
      addStatue: false
    }
  },
  created () {
    // 查询是否是黑名单用户  黑名单用户才展示按钮
    getBlacklistByApi({
      method: 'getBlacklistBy'
    }, res => {
      if (res.data.success === true && res.data.data === 'yes') {
        this.addStatue = true
      }
    })

    this.queryCom()
  },
  methods: {
    appealClick () {
      if (this.form.pleadDetails === '' || this.form.appealTheme === '') {
        this.$message.error('请完善申述信息')
        return
      }
      appealIn({
        method: 'addAppeal',
        // content: this.form.content,
        pleadDetails: this.form.pleadDetails,
        appealTheme: this.form.appealTheme
      }, res => {
        if (res.data.success === false) {
          this.$message.error(res.data.message)
          return
        }
        this.$message.info(res.data.message)
        // 如果提交成功 清空表单
        if (res.data.success === true) {
          this.form.content = ''
          this.form.appealTheme = ''
          this.form.pleadDetails = ''
          this.dialogVisible = false
        }
        // 刷新页面
        this.queryCom()
      })
    },
    add () {

    },
    handleCurrentChange (page) {
      ComPlaint({
        method: 'appealRecord',
        size: this.size,
        page: page
      }, res => {
        this.total = res.data.data.total
        this.msg = res.data.data.data
      })
    },
    queryCom () {
      ComPlaint({
        method: 'appealRecord',
        size: this.size,
        page: this.page
      }, res => {
        this.total = res.data.data.total
        this.msg = res.data.data.data
      })
    }
  }
}
</script>

<style>
#complaint {
  position: relative;
  width: 940px;
  height: 750px;
}

.complaint_title {
  display: flex;
  justify-content: space-between;
  width: 940px;
  height: 40px;
  background: #29a0dc;
  line-height: 40px;
}

.title_theme {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  padding-left: 20px;
  color: #333333;
  font-size: 14px;
  font-weight: 400;
}

.title_reply {
  width: 280px;
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}

/*内容*/
.content {
  display: flex;
  justify-content: space-between;
  width: 940px;
  height: 700px;
  margin: 20px auto;
}

/*内容2*/
.content_msg {
  display: flex;
  justify-content: space-between;
  width: 940px;
  /*height: 40px;*/
  border-bottom: 1px solid #EEEEEE;
  margin: 10px auto;
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}
.text_msg{
  width: 620px;
  display: flex;
  justify-content: space-between;
  padding:10px 20px;
  box-sizing: border-box;
  background-color: #0D5091;
}
.page {
  position: absolute;
  height: 50px;
  bottom: 0;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hint {
  text-align: center;
  margin-top: 90px;
}

.appeal-container {
  width: 500px;
  /*display: inline-block;*/
  margin: 0 auto;
  /*padding-left: 300px;*/
  /*padding-top: 50px;*/
}

.appeal-container .el-input__inner{
  width: 280px;
  height: 40px;
}

.appeal-container-content .el-textarea__inner {
  display: inline-block;
  width: 280px;
  height: 300px;
}
.appeal-container .el-input,.el-textarea {
  display: inline;
}

.submit-button {
  margin: 90px auto 0;
  text-align: center;
}

</style>
