<template>
  <div id="tags">
    <div class="tag">
      <div v-for="(item,index) in tag" :key="index" :class="{tag_listS:tag1==index}" class="tag_list"
           @click="tagButton(index)">
        {{ item.tagName }}
      </div>
      <div class="tag_list" @click="tag_read(checkedCities)">标为已读</div>
      <div class="tag_list" @click="delete_S(checkedCities)">选中删除</div>
      <div class="searchInput">
        <div class="search_s1"><el-input class="inputS" placeholder="请输入搜索标题" :clearable="true" v-model="searchValue"></el-input>
          <div class="search_s2" @click="searchButt">搜索</div>
        </div>
      </div>
    </div>
    <!--      tag标记选中-->
    <div class="tagMsg1" v-if="NullText === false">
      <div class="radio">
        <div class="tag_msg">
          <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" style="margin-left: 10px"
                       @change="handleCheckAllChange">全选
          </el-checkbox>
          <p>消息内容</p>
        </div>
        <div class="time">
          <p>状态</p>
        </div>
      </div>
      <div v-for="(item,index) in cities" :key="index" @change="messageIndex(item)">
        <el-collapse accordion v-model="activeName">
          <div class="MessageTab">
            <el-checkbox-group v-model="checkedCities" :border="false">
              <el-checkbox :label="item" @change="handleCheckedCitiesChange" :border="false">
                <el-collapse-item style="width: 900px;">
                  <template slot="title">
                    <div class="titleMessage">
                      <p class="titleP"> {{item.content.substr(0,55)}}......</p>
                      <span class="stateI">
                 <span v-if="item.state === '1'" style="color: #999999">已读</span>
                 <span v-else-if="item.state === '0'" style="color: #ea6e6e">未读</span>
                      <i class="header-icon el-icon-info" style="color: red" v-if="item.state === '0'"></i>
               </span>
                    </div>
                  </template>
                  <div class="contentMessage">
                    <p>{{item.content}}
                      <a :href="item.url" v-if="item.url !== null">点击报名</a>
                    </p>
                    <div class="delete_Message">
                      <el-popconfirm
                        title="您确定要删除这条消息吗？"
                        @confirm="delete_Butt(item)"
                      >
                        <el-button slot="reference">删除</el-button>
                      </el-popconfirm>
<!--                      <el-popover-->
<!--                        placement="top"-->
<!--                        width="160"-->
<!--                        v-model="show">-->
<!--                        <p>您确定要删除这条消息吗？</p>-->
<!--                        <div style="text-align: right; margin: 0">-->
<!--                          <el-button size="mini" type="text" @click="show = false">取消</el-button>-->
<!--                          <el-button type="primary" size="mini" @click="delete_Butt">确定</el-button>-->
<!--                        </div>-->
<!--                        <el-button slot="reference" @click="show=true">删除</el-button>-->
<!--                      </el-popover>-->
                    </div>
                  </div>
                </el-collapse-item>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-collapse>
      </div>

      <div class="page">
        <el-pagination
          :page-count="this.pages"
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next">
        </el-pagination>
      </div>
    </div>
    <div v-if="NullText === true" class="messageNull">暂无消息</div>
  </div>
</template>

<script>
import axios from 'axios'
import { MyNews } from '@/api/api'

const cityOptions = []
export default {
  name: 'tag',
  props: {},
  data () {
    return {
      activeNames: ['1'],
      tag1: 0,
      tag: [{ tagName: '全部' }, { tagName: '已读' }, { tagName: '未读' }],
      isIndeterminate: false,
      checkAll: false,
      checkedCities: [],
      cities: cityOptions,
      value: false,
      page: 1,
      size: 5,
      pages: 0,
      activeName: 1,
      NullText: false,
      searchValue: '',
      index: 0,
      show: false,
      state: '',
      id: []
    }
  },
  created () {
    this.myMessage()
  },
  methods: {
    // 点击列表已读
    messageIndex (item) {
      const id = item.id
      if (item.state === '0') {
        MyNews({
          method: 'updataMessage',
          id: id
        }, res => {
          this.myMessage()
        })
      } else if (this.state === '1') {
        return '更改完毕'
      }
    },
    // 删除
    delete_Butt (item) {
      axios.defaults.headers.token = localStorage.getItem('token')
      MyNews({
        method: 'delMessage',
        ids: [item.id]
      }, res => {
        this.$message({
          message: '删除成功~'
        })
        setTimeout(res => {
          this.tag1 = 0
          this.myMessage()
        })
      }, 1000)
    },
    delete_S (checkedCities) {
      if (checkedCities.length === 0) {
        this.$message({
          message: '请输选中消息后删除~',
          type: 'error'
        })
      } else {
        for (let i = 0; i < checkedCities.length; i++) {
          this.id.push(checkedCities[i].id)
        }
        axios.defaults.headers.token = localStorage.getItem('token')
        MyNews({
          method: 'delMessage',
          ids: this.id
        }, res => {
          this.$message({
            message: '删除成功~'
          })
          setTimeout(res => {
            this.tag1 = 0
            this.checkedCities = []
            this.checkAll = false
            this.myMessage()
          })
        }, 1000)
      }
    },
    // 查询
    searchButt () {
      if (this.searchValue === '') {
        this.$message({
          message: '请输入搜索内容~',
          type: 'error'
        })
      } else {
        axios.defaults.headers.token = localStorage.getItem('token')
        MyNews({
          method: 'myMessage',
          state: '',
          content: this.searchValue,
          size: this.size,
          page: this.page
        }, res => {
          if (this.total <= 1) {
            this.value = true
          }
          this.total = res.data.data.total
          // for (let i = 0; i < res.data.data.data.length; i++) {
          this.cities = res.data.data.data
          // }
          if (res.data.data.data.length === 0) {
            this.NullText = true
          } else {
            this.pages = res.data.data.pages
            this.NullText = false
          }
        })
      }
    },
    tagButton (index) {
      if (index === 0) {
        this.state = ''
      } else if (index === 1) {
        this.state = 1
      } else if (index === 2) {
        this.state = 2
      }
      this.tag1 = index
      if (index === 0) {
        axios.defaults.headers.token = localStorage.getItem('token')
        MyNews({
          method: 'myMessage',
          state: '',
          size: this.size,
          page: this.page
        }, res => {
          if (this.total <= 1) {
            this.value = true
          }
          this.total = res.data.data.total
          // for (let i = 0; i < res.data.data.data.length; i++) {
          this.cities = res.data.data.data
          // }
          if (res.data.data.data.length === 0) {
            this.NullText = true
          } else {
            this.pages = res.data.data.pages
            this.NullText = false
          }
        })
      } else if (index === 1) {
        axios.defaults.headers.token = localStorage.getItem('token')
        MyNews({
          method: 'myMessage',
          state: '1',
          size: this.size,
          page: this.page
        }, res => {
          if (this.total <= 1) {
            this.value = true
          }
          this.total = res.data.data.total
          // for (let i = 0; i < res.data.data.data.length; i++) {
          this.cities = res.data.data.data
          if (res.data.data.data.length === 0) {
            this.NullText = true
          } else {
            this.pages = res.data.data.pages
            this.NullText = false
          }
          // }
        })
      } else if (index === 2) {
        axios.defaults.headers.token = localStorage.getItem('token')
        MyNews({
          method: 'myMessage',
          state: '0',
          size: this.size,
          page: this.page
        }, res => {
          if (this.total <= 1) {
            this.value = true
          }
          this.total = res.data.data.total
          // for (let i = 0; i < res.data.data.data.length; i++) {
          this.cities = res.data.data.data
          // }
          if (res.data.data.data.length === 0) {
            this.NullText = true
          } else {
            this.pages = res.data.data.pages
            this.NullText = false
          }
        })
      }
    },
    handleCheckAllChange (val) {
      this.checkedCities = val ? this.cities : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      var checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length
    },
    tag_read (checkedCities) {
      if (checkedCities.length === 0) {
        this.$message({
          message: '请选择~',
          type: 'error'
        })
      } else {
        for (let i = 0; i < checkedCities.length; i++) {
          axios.defaults.headers.token = localStorage.getItem('token')
          MyNews({
            method: 'updataMessage',
            id: checkedCities[i].id
          }, res => {
            this.$message({
              message: '已读~',
              type: 'success'
            })
            setTimeout(res => {
              this.checkedCities = []
              this.checkAll = false
              this.myMessage()
            }, 500)
          })
        }
      }
    },
    async myMessage () {
      axios.defaults.headers.token = localStorage.getItem('token')
      MyNews({
        method: 'myMessage',
        state: '',
        size: this.size,
        page: this.page
      }, res => {
        if (this.total <= 1) {
          this.value = true
        }
        for (let i = 0; i < res.data.data.data.length; i++) {
          this.cities = res.data.data.data
        }
        if (res.data.data.data.length === 0) {
          this.NullText = true
        } else {
          this.pages = res.data.data.pages
          this.NullText = false
        }
      })
    },
    async  handleCurrentChange (page) {
      axios.defaults.headers.token = localStorage.getItem('token')
      MyNews({
        method: 'myMessage',
        state: this.state,
        size: this.size,
        page: page
      }, res => {
        if (this.total <= 1) {
          this.value = true
        }
        for (let i = 0; i < res.data.data.data.length; i++) {
          this.cities = res.data.data.data
        }
        this.pages = res.data.data.pages
      })
    }
  }
}
</script>

<style scoped>
.MessageTab /deep/ .el-collapse-item__header {
  border: none;
}
.MessageTab /deep/ .el-collapse-item__wrap {
  border: none;
}
.contentMessage{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.delete_Message{
  /*background: #c5c7c7;*/
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7c7b7b;
  padding-right: 22px;
}
.messageNull{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 300px;
  background: #a8a8a8;
  margin: 20px auto;
  opacity: 0.8;
  color: #dedede;
  font-size: 22px;
  letter-spacing: 2px;
}
.searchInput{
  height: 50px;
}
.search_s1{
  display: flex;
  align-items: center;
  line-height: 50px;
  padding-left: 20px;
  box-sizing: border-box;
}
.search_s2{
  height: 40px;
  border: 1px solid #10539B;
  border-radius: 8px;
  margin-left: 12px;
  text-align: center;
  line-height: 40px;
  color: #10539B;
  font-size: 14px;
  cursor: pointer;
  padding: 0 14px;
  font-weight: 500;
}
.inputS{
  width: 400px;
}
.MessageTab{
  width: 100%;
  padding: 20px 23px;
  box-sizing: border-box;
}
.titleMessage{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.titleP{
  width: 100%;
  /*background-color: #7ac23c;*/
  display: flex;
  justify-content: space-between;
  padding: 0 58px;
  box-sizing: border-box;
  word-break: normal;
  white-space: pre-line;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 14px;
}
.stateI{
  /*width: 120px;*/
  display: flex;
  /*background-color: #7ac23c;*/
  justify-content: space-between;
  align-items: center;
}
.el-collapse-item__content>.contentMessage>p{
  word-wrap: break-word;
  word-break: break-all;
  padding: 0 58px;
  white-space: normal;
}

/*标记*/
.tag {
  display: flex;
  width: 940px;
  height: 56px;
  background-color: #E4F1FF;
  margin: 0 auto;
}

/*标记list*/
.tag_list {
  /*width: 60px;*/
  height: 30px;
  border: 1px solid #10539B;
  border-radius: 4px;
  margin-left: 12px;
  text-align: center;
  line-height: 30px;
  margin-top: 10px;
  color: #10539B;
  font-size: 14px;
  cursor: pointer;
  padding: 0 14px;
  font-weight: 500;
}

/*tag选中样式*/
.tag_listS {
  background: #10539B;
  color: white;
}

/*tag选中信息*/
.tagMsg1 {
  position: relative;
  width: 940px;
  height: 734px;
  margin: 20px auto;
}

.radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 930px;
  height: 40px;
  background: #F1F3F4;
  padding-left: 12px;
  color: #333333;
  font-size: 14px
}

.tag_msg {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #333333;
  font-weight: 400;
}

.time {
  display: flex;
  align-items: center;
  width: 60px;
  height: 40px;
  justify-content: center;
  color: #333333;
}

.msg_list {
  width: 765px;
  display: flex;
  justify-content: space-between;
  margin-left: 60px;
}

.checkbox {
  width: 940px;
  height: 50px;
  margin: 20px auto;
}

.page {
  position: absolute;
  height: 50px;
  bottom: 0;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
