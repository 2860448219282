<template>
  <div id="enterprise">
    <div class="ent">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!--        主账号-->
        <el-tab-pane label="主账号信息" name="account">
          <div class="hand" @click="updateButton()" v-if="accountType !== 'sub'">修改信息</div>
          <div class="titleT">
            <p>供应商名称： <span>{{ message.supplierName }}</span></p>
            <p>联系地址：<span>{{ message.contactAddress }}</span></p>
            <p>营业期限开始时间：<span>{{ message.businessTermBeginTime }}</span></p>
            <p>营业期限结束时间：<span>{{ message.businessTermEndTime }}</span></p>
            <p>法定代表人：<span>{{ message.legalPerson }}</span></p>
            <p>法定代表人联系电话：<span>{{ message.legalPersonPhone }}</span></p>
            <p>联系人：<span>{{ message.contacts }}</span></p>
            <p>联系人身份证号码：<span>{{ message.contactsIdNumber }}</span></p>
            <p>联系人联系电话：<span>{{ message.contactsPhone }}</span></p>
            <p>纳税人识别号：<span>{{ message.identifyingCode }}</span></p>
            <p>税务信用等级：<span>{{ message.creditRating }}</span></p>
            <p>开户银行：<span>{{ message.depositBank }}</span></p>
            <p>账号号码：<span>{{ message.accountNumber }}</span></p>
            <p style="line-height: 25px">主要经营类别：<span>{{ message.businessCategory }}</span></p>
            <p style="line-height: 25px">主要经营内容：<span>{{ message.businessContent }}</span></p>
            <p>主要经营区域：<span>{{ message.businessArea }}</span></p>
            <p>注册地址：<span>{{ message.registerAddress }}</span></p>
            <p>注册资本/实缴资本：<span>{{ message.capital }}</span></p>
<!--            <div>-->
<!--              <p>附件：</p>-->
<!--              <div v-for="(item, index) in message.atts" :key="index">-->
<!--                <a :href="item.fileUrl">{{ item.fileName }}</a>-->
<!--              </div>-->
<!--            </div>-->
          </div>

          <el-dialog
            :visible.sync="updateDetail"
            title="修改信息"
            width="700px">
            <el-form
              ref="form"
              :model="updateMessage"
              label-width="160px"
              :rules="rules">
              <el-form-item label="供应商名称:" prop="supplierName">
                <el-input v-model="updateMessage.supplierName" maxlength="100"></el-input>
              </el-form-item>
              <el-form-item label="联系地址:" prop="contactAddress">

                <el-input v-model="updateMessage.contactAddress" maxlength="100"></el-input>
              </el-form-item>
              <el-form-item label="营业期限:" prop="businessTerm">

                <span style="font-size: 12px;">开始时间: </span>
                <el-date-picker
                  style="width: 30%;"
                  v-model="updateMessage.businessTermBeginTime"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
                <span style="font-size: 12px; margin-left: 10px"> 结束时间: </span>
                <el-date-picker
                  style="width: 30%;"
                  v-model="updateMessage.businessTermEndTime"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
                <el-button type="primary" style="padding: 8px 20px; border-radius: 20px" @click="foverBut">永久</el-button>
              </el-form-item>
              <el-form-item label="法定代表人:" prop="legalPerson">

                <el-input v-model="updateMessage.legalPerson"></el-input>
              </el-form-item>
              <el-form-item label="法定代表人联系电话:" prop="legalPersonPhone">

                <el-input v-model="updateMessage.legalPersonPhone" maxlength="11"></el-input>
                <el-input v-model="codeS" class="input_p" placeholder="请输入验证码" maxlength="5" style="width: 50%; margin-right: 4px"></el-input>
                <span v-show="showCode" :datafld="!showCode" class="code_get" @click="code_time">获取验证码</span>
                <span v-show="!showCode" class="code_get">{{ code }}秒后获取</span>
              </el-form-item>
              <el-form-item label="联系人:" prop="contacts">

                <el-input v-model="updateMessage.contacts" maxlength="100"></el-input>
              </el-form-item>
              <el-form-item label="联系人身份证号码:" prop="contactsIdNumber">
                <el-input @blur="userID" v-model="updateMessage.contactsIdNumber" maxlength="18"></el-input>
              </el-form-item>
              <el-form-item label="联系人联系电话:" prop="contactsPhone">

                <el-input @blur="setValue" v-model="updateMessage.contactsPhone" maxlength="11"></el-input>
              </el-form-item>
              <el-form-item label="纳税人识别号:" prop="identifyingCode">

                <el-input v-model="updateMessage.identifyingCode" maxlength="100" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="税务信用等级:" prop="creditRating">

                <el-select v-model="updateMessage.creditRating" placeholder="请选择您的税务信用等级" style="width: 100%">
                  <el-option
                    v-for="item in lv"
                    :key="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="开户银行:" prop="depositBank">
                <el-input v-model="updateMessage.depositBank" maxlength="100"></el-input>
              </el-form-item>
              <el-form-item label="账号号码:" prop="accountNumber">
                <el-input v-model="updateMessage.accountNumber" maxlength="100"></el-input>
              </el-form-item>
              <el-form-item label="主要经营类别:" prop="accountNumber">
                <el-input v-model="updateMessage.businessCategory" maxlength="100" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="主要经营内容:" prop="businessContent">
                <el-select  v-model="businessContentValue" placeholder="请选择您的主要经营内容" clearable  @change="inputMulti">
                  <el-option v-for="(item, index) in businessContent" :key="index" :label="item" :value="item">
                  </el-option>
                </el-select>
                <el-input style="width: 275px" v-model="businessContentCustom" placeholder="自定义内容" maxlength="100"></el-input>
              </el-form-item>

              <el-form-item label="主要经营区域:">
                <el-cascader
                  size="large"
                  :options="options"
                  v-model="updateMessage.regionId"
                  :placeholder="message.businessArea"
                  expand-trigger="hover"
                  ref="cascadeAdder"
                  @change="handleChange">
                </el-cascader>
              </el-form-item>
              <el-form-item label="注册地址:" prop="registerAddress">
                <el-input v-model="updateMessage.registerAddress" maxlength="100"></el-input>
              </el-form-item>
              <el-form-item label="注册资本/实缴资本:" prop="capital">
                <el-input v-model="updateMessage.capital" maxlength="100"></el-input>
              </el-form-item>
              <el-form-item label="附件:">
                <el-upload
                  accept=".jpg,.png,.bmp,.txt,.doc,.wps,.docx,.xls,.xlsx,.pdf,.rar,.zip"
                  :headers="headers"
                  class="upload-demo"
                  action='https://www.zjhy.cc/api/seeyon/rest/euAttachment'
                  :auto-upload="false"
                  :data="fileData"
                  multiple
                  :limit="10"
                  :file-list="fileList1"
                  :before-remove="filedel"
                  :on-change="feiladd"
                  ref="upload">
                    <el-button class="upload-button" size="small" type="primary">点击上传</el-button>
                    <span style="color: red;padding: 0 20px">注：单文件上传请小于10MB</span>
                    <div slot="tip" class="el-upload__tip"></div>
                </el-upload>
              </el-form-item>
            </el-form>
            <div class="update-button">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="updateEnterpriseClick">确定</el-button>
            </div>
          </el-dialog>
<!--          <div class="hand" @click="dialogVisible=true">移交</div>-->
        </el-tab-pane>
        <!--        子账号-->
        <el-tab-pane label="子账号管理" name="second">
          <div class="new_Second" @click="createSecond" v-if="accountType !== 'sub'">创建</div>
          <el-dialog
            :before-close="handleClose2"
            :visible.sync="show"
            title="创建子账号"
            width="480px">
            <div class="xian"></div>
            <div class="input_new">
              <el-form :label-position="labelPosition" :model="formLabelAlign" label-width="80px">
                <el-form-item label="姓名" label-width="90px">
                  <el-input v-model="formLabelAlign.name" placeholder="请输入您的姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号码" label-width="90px">
                  <el-input v-model="formLabelAlign.phone" placeholder="请输入您的手机号码" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="验证码" label-width="90px">
                  <el-input v-model="formLabelAlign.code" placeholder="请输入您验证码"></el-input>
                  <div v-show="show3" :datafld="!show3" class="getCode" @click="code_time1">获取验证码</div>
                  <div v-show="!show3" class="getCode">{{ code }}秒后获取</div>
                </el-form-item>
              </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
            <div class="butBor">
               <div @click="show=false">取消</div>
               <div @click="new_determine()">确定</div>
             </div>
            </span>
          </el-dialog>

          <el-table
            :data="tableData"
            border
            style="width: 100%;">
            <el-table-column
              align="center"
              label="序号"
              type="index"
              width="150">
            </el-table-column>
            <el-table-column
              align="center"
              label="姓名"
              prop="name"
              width="282">
            </el-table-column>
            <el-table-column
              align="center"
              label="手机号码"
              prop="phone"
              width="281">
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="230">
              <template slot-scope="scope">
                <div class="editorV">
                  <div class="editor" @click="Editor(scope.$index)" v-if="accountType !== 'sub'">编辑</div>
                  <div class="editor" @click="Delete(scope.$index)" v-if="accountType !== 'sub'">删除</div>
                  <div class="editor" v-if="accountType === 'sub'">无权限</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <!--      修改子账号-->
      <el-dialog
        v-if="accountType !== 'sub'"
        :before-close="handleClose2"
        :visible.sync="show1"
        title="修改子账号"
        width="480px">
        <div class="xian"></div>
        <div class="input_new">
          <el-form :label-position="labelPosition" :model="formLabelAlign" label-width="80px">
            <el-form-item label="姓名" label-width="90px">
              <el-input v-model="form.name" :placeholder="form.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" label-width="90px">
              <el-input v-model="form.phone" :placeholder="form.phone" maxlength="11"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <div class="butBor">
               <div @click="show1=false">取消</div>
               <div @click="upda_determine()">确定</div>
             </div>
            </span>
      </el-dialog>
      <!--      删除子账号-->
      <el-dialog
        v-if="this.accountType !== 'sub'"
        :before-close="handleClose"
        :visible.sync="show2"
        title="删除子账号"
        width="480px">
        <div class="xian"></div>
        <div class="butBo">
          <div class="sigh">!</div>
          <p>是否删除该子账号？</p>
        </div>
        <span slot="footer" class="dialog-footer">
             <div class="butBor">
               <div @click="show2=false">否</div>
               <div @click="Delete1()">是</div>
             </div>
            </span>
      </el-dialog>
      <el-dialog
        :before-close="handleClose1"
        :visible.sync="dialogVisible1"
        title="请确认删除操作"
        width="480px">
        <div class="xian"></div>
        <div class="butBo">
          <p>删除子账户后,信息默认移交给主账户!</p>
        </div>
        <span slot="footer" class="dialog-footer">
           <div class="butBor">
               <div @click="dialogVisible1=false">否</div>
               <div @click="confirm()">是</div>
             </div>
            </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {
  EnMe,
  saveHome,
  VtPhone,
  // delUsers,
  // eslint-disable-next-line no-unused-vars
  updateEnterprise,
  // eslint-disable-next-line no-unused-vars
  fileDel,
  getContentApi,
  // eslint-disable-next-line no-unused-vars
  getNumberApi, delUsers, isPhoneCli, enterprise
} from '@/api/api'
import { CodeToText, regionData } from 'element-china-area-data'
const cityOptions = ['子账号1', '子账号2', '子账号3', '子账号4', '子账号5']
const TIME_COUNT = 60 // 验证码倒计时
export default {
  name: 'enterprise',
  props: {},
  data () {
    return {
      accountType: localStorage.getItem('accountType'),
      addStatue: false,
      options: regionData,
      selectedOptions: '',
      codeS: '',
      showCode: true,
      material: [],
      fileList: 0,
      fileList1: [],
      lv: ['A', 'B', 'C', 'D'],
      activeName: 'account',
      fileDelList: [],
      message: [],
      updateMessage: {},
      messageCode: 0,
      messageCode1: '',
      fileData: {
        reference: '',
        subReference: ''
      },
      dialogVisible: false,
      updateDetail: false,
      dialogVisible1: false,
      cities: cityOptions,
      tableData: [],
      showT: true,
      show: false,
      show1: false,
      show2: false,
      show3: true,
      code: '',
      timer: null,
      labelPosition: 'right',
      formLabelAlign: {
        name: '',
        phone: '',
        code: ''
      },
      form: {
        name: '',
        phone: '',
        regionId: ''
      },
      index: 0,
      subID: '',
      regionId: '',
      rules: {
        supplierName: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        contactAddress: [
          { required: true, message: '请输入联系地址', trigger: 'blur' }
        ],
        businessTermBeginTime: [
          { required: true, message: '请输入营业期限开始时间', trigger: 'blur' }
        ],
        businessTermEndTime: [
          { required: true, message: '请输入营业期限结束时间', trigger: 'blur' }
        ],
        legalPersonPhone: [
          { required: true, message: '请输入法定代表人联系电话', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的联系电话', trigger: 'blur' }
        ],
        contactsIdNumber: [
          { required: true, message: '请输入联系人身份证号码', trigger: 'blur' },
          { min: 18, max: 18, message: '请输入正确的身份证号', trigger: 'blur' }
        ],
        identifyingCode: [
          { required: true, message: '请输入纳税人识别号', trigger: 'blur' }
        ],
        depositBank: [
          { required: true, message: '请输入开户银行', trigger: 'blur' }
        ],
        registerAddress: [
          { required: true, message: '请输入您的注册地址', trigger: 'blur' }
        ],
        capital: [
          { required: true, message: '请输入您的注册资本/实缴资本', trigger: 'blur' }
        ],
        legalPerson: [
          { required: true, message: '请输入您的法定代表人', trigger: 'blur' }
        ],
        contacts: [
          { required: true, message: '请输入您的联系人', trigger: 'blur' }
        ],
        contactsPhone: [
          { required: true, message: '请输入您的联系人联系电话', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的联系电话', trigger: 'blur' }
        ],
        creditRating: [
          { required: true, message: '请输入您的税务信用等级', trigger: 'blur' }
        ],
        accountNumber: [
          { required: true, message: '请输入您的账户号码', trigger: 'blur' }
        ],
        businessArea: [
          { required: true, message: '请选择您的经营区域', trigger: 'blur' }
        ]
      },
      businessContent: [],
      businessContentCustom: '', // 自定义内容
      businessContentValue: '',
      fileType: '' // 文件类型
    }
  },
  created () {
    this.msg()
    this.msgS()
  },
  computed: {
    headers () {
      const token = localStorage.getItem('token')
      return {
        token: token
      }
    }
  },
  methods: {
    cancel () {
      this.msg()
      this.updateDetail = false
    },
    setValue (e) { // 联系人手机号验证
      if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.updateMessage.contactsPhone) || this.updateMessage.contactsPhone === '') {
        this.$message.error('请输入正确的手机号码！')
        this.updateMessage.contactsPhone = ''
      }
    },
    // 身份证校验
    userID () {
      if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(this.updateMessage.contactsIdNumber) || this.updateMessage.contactsIdNumber === '') {
        this.$message.error('请输入正确的身份证号！')
        this.updateMessage.contactsIdNumber = ''
      }
    },
    createSecond () {
      // 判断是否是黑名单用户
      const blackUser = localStorage.getItem('blackUser')
      if (blackUser === true) {
        this.$message.error('需要申诉并且通过之后才能正常使用')
        return
      }
      this.show = true
    },
    // 主要经营区域
    handleChange (value) {
      this.updateMessage.regionId = value.join(',')
      const a = value[0]
      const b = value[1]
      const c = value[2]
      const bus = CodeToText[a] + CodeToText[b] + CodeToText[c]
      this.updateMessage.businessArea = bus
    },
    // 获取经营内容
    inputMulti (val) {
      getContentApi({
        method: 'getSmallMaterial',
        largeClass: this.message.businessCategory
      }, res => {
        if (res.data.success === false) {
          this.$message.error(res.data.message)
        } else {
          this.businessContent = res.data.data
        }
      })
      this.businessContentValue = val
    },
    updateButton () {
      // 判断是否是黑名单用户
      const blackUser = localStorage.getItem('blackUser')
      if (blackUser === true) {
        this.$message.error('需要申诉并且通过之后才能正常使用')
        return
      }
      // this.updateMessage.businessContent
      this.businessContentCustom = this.updateMessage.businessContent.split(',')
      this.businessContentValue = this.businessContentCustom[0]
      this.businessContentCustom = this.businessContentCustom[1]
      this.updateDetail = true
      const temp = JSON.stringify(this.message)
      this.updateMessage = JSON.parse(temp)
      // 循环将附件添加
      this.fileList = [] // 循环前将数据清空
      this.message.atts.map((item, index, arr) => {
        const temp = {
          name: item.fileName,
          url: item.fileUrls
        }
        this.fileList.push(temp)
      })
    },
    feiladd (file, fileList) {
      // 文件类型过滤
      const fileType = file.name.replace(/.+\./, '')
      if (['jpg', 'png', 'bmp', 'txt', 'doc', 'wps', 'docx', 'xls', 'xlsx', 'pdf', 'rar', 'zip'].indexOf(fileType.toLowerCase()) === -1) {
        this.$msgbox({
          title: '',
          message: '文件格式错误，请重新上传。',
          type: 'warning'
        })
        fileList.splice(-1)
      } else {
        // 文件大小过滤
        this.fileSize = Number(file.size / 1024 / 1024)
        if (this.fileSize > 10) {
          this.$msgbox({
            title: '',
            message: '文件大小不能超过10MB，请重新上传。',
            type: 'warning'
          })
          fileList.splice(-1)
        }
      }
      this.fileList = fileList.length
    },
    filedel (file, fileList) {
      this.message.atts.map((item, index, arr) => {
        if (item.fileName === file.name) {
          this.fileDelList.push(file.name)
        }
      })
      // 删除图片  点击删除时会将文件名保存到fileDelList，点击提交修改时才会删除图片
      this.fileDelList.map(item => {
        this.message.atts.map(itemTwo => {
          if (item === itemTwo.fileName) {
            fileDel({
              method: 'delAttBy',
              ascId: itemTwo.ascId
            }, itemThree => {
            })
          }
        })
      })
      const filelist = fileList.length - 1
      this.fileList = filelist
    },

    foverBut () {
      this.updateMessage.businessTermEndTime = '2999-12-31'
    },
    funcChina (obj) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(obj)) {
        // alert('不能含有汉字！')
        return false
      }
      return true
    },
    // 修改企业账户信息
    async chooseContent () {
      if (this.businessContentCustom !== undefined && this.businessContentValue !== undefined) {
        this.updateMessage.businessContent = this.businessContentValue + '' + '/' + '' + this.businessContentCustom
      } else if (this.businessContentCustom !== undefined) {
        this.updateMessage.businessContent = this.businessContentCustom
      } else if (this.businessContentValue !== undefined) {
        this.updateMessage.businessContent = this.businessContentValue
      }
    },
    updateEnterpriseClick () {
      this.chooseContent()
      const form = this.updateMessage
      this.updateMessage.regionId = this.updateMessage.regionId.toString()
      if (this.message.legalPersonPhone !== this.updateMessage.legalPersonPhone) {
        // 说明电话号码有改动 判断验证码
        if (this.messageCode !== this.codeS) {
          this.$message.error('请输入正确的验证码')
          return
        }
      }
      if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(form.legalPersonPhone) || form.legalPersonPhone === '') {
        this.$message.error('请输入正确的法定代表人联系电话')
        return
      } else if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(form.contactsPhone) || form.contactsPhone === '') {
        this.$message.error('请输入正确的联系人电话')
        return
      } else if (form.supplierName.length < 4) {
        this.$message.error('供应商名字不能少于四位')
        return
      } else if (form.contactsIdNumber.length !== 18) {
        this.$message.error('请输入正确的身份证号')
        return
      } else if (this.funcChina(form.legalPersonPhone) === false) {
        this.$message.error('法定代表人联系电话不能含有中文')
        return
      } else if (this.funcChina(form.contactsIdNumber) === false) {
        this.$message.error('身份证号不能含有中文')
        return
      } else if (this.funcChina(form.identifyingCode) === false) {
        this.$message.error('纳税人识别号不能含有中文')
        return
      } else if (this.funcChina(form.contactsPhone) === false) {
        this.$message.error('联系人电话不能含有中文')
        return
      } else if (this.funcChina(form.accountNumber) === false) {
        this.$message.error('账户号码不能含有中文')
        return
      } else if (this.selectedOptions.length !== 3) {
        this.$message.error('请选择主要经营区域')
        return
      } else if (this.fileList === 0) {
        this.$message.error('请上传附件')
        return
      }
      const formName = 'form'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateMessage.supplyType = 'company'
          updateEnterprise({
            method: 'updateEnterpriseSupply',
            data: [
              this.updateMessage
            ]
          }, res => {
            if (res.data.success === true) {
              this.$message.success(res.data.message)
              this.msg()
              // this.message = this.updateMessage
              // 附件
              this.fileData.reference = res.data.data.reference
              this.fileData.subReference = res.data.data.subReference
              this.$refs.upload.submit()// 文件上传
            } else {
              this.$message.error(res.data.message)
            }
            this.updateDetail = false
          })
        } else {
          this.$message.error('请完善表单')
          return false
        }
      })
    },
    handleClick (tab, event) {
    },
    async msg () {
      axios.defaults.headers.token = localStorage.getItem('token')
      await EnMe({
        method: 'terpriseSupplier'
      }, res => {
        if (res.data.data === null) {
          return
        }
        this.updateMessage = res.data.data
        this.message = res.data.data
        this.inputMulti()
        this.selectedOptions = res.data.data.regionId.split(',')
        this.updateMessage.regionId = this.selectedOptions
        const temp = JSON.stringify(this.message)
        this.updateMessage = JSON.parse(temp)
        // 循环将附件添加
        this.fileList1 = [] // 循环前将数据清空
        this.message.atts.map((item, index, arr) => {
          const temp = {
            name: item.fileName,
            url: item.fileUrl
          }
          this.fileList1.push(temp)
        })
      })
    },
    async msgS () {
      axios.defaults.headers.token = localStorage.getItem('token')
      EnMe({
        method: 'member',
        uintId: localStorage.getItem('unitId'),
        state: '0',
        size: '10',
        page: '1',
        accountType: 'sub'
      }, res => {
        this.tableData = res.data.data.data
      })
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {
        })
    },
    handleClose1 (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {
        })
    },
    handleClose2 (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {
        })
    },
    yes () {
      this.dialogVisible = false
      this.dialogVisible1 = true
    },
    confirm () {
      this.dialogVisible1 = false
      this.Delete1()
    },
    // new创建
    new_determine () {
      isPhoneCli({
        method: 'testPhone',
        phone: this.formLabelAlign.phone,
        authCode: this.formLabelAlign.code
      }, resPhone => {
        if (resPhone.data.success === true) {
          if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.formLabelAlign.phone) || this.formLabelAlign.phone === '') {
            this.$message.error('请输入正确的手机号码！')
          } else if (this.formLabelAlign.name === '' || this.formLabelAlign.name === null) {
            this.$message.error('请输入姓名！')
          } else if (this.formLabelAlign.code === '' || this.formLabelAlign.code !== this.messageCode1) {
            this.$message.error('验证码错误！')
          } else {
            axios.defaults.headers.token = localStorage.getItem('token')
            saveHome({
              method: 'saveSubaccount',
              data: [{
                unitId: localStorage.getItem('unitId'),
                name: this.formLabelAlign.name,
                phone: this.formLabelAlign.phone
                // password: '',
                // accountType: 'sub'
              }]
            }, res => {
              if (res.data.success === true) {
                this.subID = res.data.data
                this.$message({
                  message: '添加成功~',
                  type: 'success'
                })
                this.tableData.push({
                  name: this.formLabelAlign.name,
                  phone: this.formLabelAlign.phone
                })
                this.formLabelAlign.name = ''
                this.formLabelAlign.phone = ''
                this.formLabelAlign.code = ''
                this.show = false
              } else {
                this.$message.error(res.data.message)
              }
            })
          }
        } else {
          this.$message.error(resPhone.data.message)
        }
      })
    },
    // 编辑
    Editor (index) {
      this.form.name = this.tableData[index].name
      this.form.phone = this.tableData[index].phone
      this.show1 = true
      this.subID = this.tableData[index].id
    },
    Delete (index) {
      this.dialogVisible1 = true // 选择移交人
      this.index = index
    },
    Delete1 () {
      if (this.accountType === 'sub') {
        this.$message.error('子账户无权限操作')
      } else {
        delUsers({
          method: 'deleteMember',
          deleteId: this.tableData[this.index].id
        }, res => {
          this.tableData.splice(this.index, 1)
          this.$message({
            message: '删除成功~',
            type: 'success'
          })
          this.show2 = false
        })
      }
    },
    code_time () {
      if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.updateMessage.legalPersonPhone) || this.updateMessage.legalPersonPhone === '') {
        this.$message.error('请输入正确的手机号码！')
      } else if (!this.timer) {
        this.code = TIME_COUNT
        this.showT = false
        axios.defaults.headers.token = localStorage.getItem('token')
        VtPhone({
          method: 'authCode',
          phone: this.updateMessage.legalPersonPhone
        }, res => {
          this.$message.success('验证码已发送至您的手机！')
          this.messageCode = res.data.data
          this.showCode = !this.showCode
        })
        this.timer = setInterval(() => {
          if (this.code > 0 && this.code <= TIME_COUNT) {
            this.code--
          } else {
            this.showT = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    code_time1 () {
      if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.formLabelAlign.phone) || this.formLabelAlign.phone === '') {
        this.$message.error('请输入正确的手机号码！')
      } else if (!this.timer) {
        this.code = TIME_COUNT
        this.showT = false
        axios.defaults.headers.token = localStorage.getItem('token')
        VtPhone({
          method: 'authCode',
          phone: this.formLabelAlign.phone
        }, res => {
          this.$message.success('验证码已发送至您的手机！')
          this.messageCode1 = res.data.data
          this.show3 = !this.show3
        })
        this.timer = setInterval(() => {
          if (this.code > 0 && this.code <= TIME_COUNT) {
            this.code--
          } else {
            this.showT = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    upda_determine () {
      // enterprise({
      //   method: 'getsupplierPhone',
      //   contactsPhone: this.form.contactsPhone
      // }, resPhone => {
      //   if (resPhone.data.success === false || resPhone.data.data === '1') {
      //     this.$message.error(resPhone.data.message)
      //     return
      //   }
      if (this.funcChina(this.form.phone) === false) {
        this.$message.error('手机号码不能含有中文')
        return
      }
      if (this.form.name !== '' || this.form.phone !== '') {
        axios.defaults.headers.token = localStorage.getItem('token')
        saveHome({
          method: 'saveSubaccount',
          data: [{
            unitId: localStorage.getItem('unitId'),
            id: this.subID,
            name: this.form.name,
            phone: this.form.phone
            // password: ''
            // accountType: 'sub'
          }]
        }, res => {
          this.$message({
            message: '编辑成功~',
            type: 'success'
          })
          this.show1 = false
          this.msgS()
        })
      } else {
        this.$message({
          message: '请输入姓名或者手机号码！！！',
          type: 'error'
        })
      }
      // })
    },
    beforeUpload (file) {
    }
  }
}
</script>

<style scoped>
#enterprise {
  display: flex;
  width: 940px;
  height: 750px;
}

.ent {
  width: 980px;
  height: 1px;
}

.titleT {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 38px;
}

.titleT > p > span {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}

.hand {
  float: right;
  width: 100px;
  height: 42px;
  background: #0e72dc;
  border-radius: 4px;
  margin: 10px 0;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
}

.xian {
  position: absolute;
  width: 88%;
  height: 1px;
  background-color: #EEEEEE;
  margin: -35px auto;
}

.sigh {
  width: 23px;
  height: 23px;
  background-color: #FF9C31;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 23px;
  margin-right: 30px;
}

.butBo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin: 0 auto;
}

.butBor {
  display: flex;
  width: 70%;
  height: 50px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  /*background-color: #EA5D5C;*/
}

.butBor > div:first-child {
  width: 100px;
  height: 42px;
  background: #D5D5D5;
  border-radius: 4px;
  text-align: center;
  line-height: 42px;
  font-size: 16px;
  color: #666666;
  cursor: pointer;
}

.butBor > div:nth-child(2n) {
  width: 100px;
  height: 42px;
  background: #10539B;
  border-radius: 4px;
  text-align: center;
  line-height: 42px;
  font-size: 16px;
  color: #FFFFFF;
  cursor: pointer;
}

.ckbox {
  display: block;
  margin-bottom: 10px;
  height: 25px;
  line-height: 40px;
}

/*创建*/
.new_Second {
  width: 36px;
  height: 18px;
  border: 1px solid #10539B;
  border-radius: 2px;
  text-align: center;
  line-height: 18px;
  margin-left: auto;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  color: #10539B;
  margin-bottom: 4px;
}

.editorV {
  width: 200px;
  display: flex;
  justify-content: space-evenly;
}

.editor {
  width: 46px;
  height: 24px;
  border: 1px solid #10539B;
  border-radius: 3px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #10539B;
  cursor: pointer;
}

.input_new {
  height: 150px;
}

.getCode {
  position: absolute;
  margin-left: 250px;
  margin-top: -40px;
  width: 100px;
  height: 40px;
  background: #EEEEEE;
  border-radius: 0px 4px 4px 0px;
  text-align: center;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
  font-size: 14px;
  cursor: pointer;
}

.code_get {
  /*position: absolute;*/
  display: inline-block;
  width: 100px;
  height: 40px;
  text-align: center;
  background-color: #EEEEEE;
  color: #666666;
  line-height: 37px;
  /*margin-left: -140px;*/
  cursor: pointer;
  /*margin-left: 200px;*/
}

.update-button {
  text-align: right;
}

.input_p {
  display: inline-block;
  width: 300px;
  margin-top: 10px;
}
</style>
