<template>
  <div class="all">
    <div class="topTitle">
      <div class="title">
        <div class="imgLogo"><img class="home" src="../assets/img/logo2.png">
          <p>环宇建筑科技股份有限公司</p>
        </div>
        <div class="tab_but">
          <router-link :to="{name:'Home'}" class="but_tab"> 网站首页</router-link>
          <router-link :to="{name:'Recruiting'}" class="but_tab"> 招标公告</router-link>
          <router-link :to="{name:'Winning'}" class="but_tab"> 中标公示</router-link>
          <router-link :to="{name:'Service'}" class="but_tab_Service"> 企业服务</router-link>
          <router-link :to="{name:'Help'}" class="but_tab"> 帮助中心</router-link>
          <div class="but_tab" @click="exitBut" v-if="id !== null">退出登录</div>
        </div>
      </div>
      <!--      左侧导航栏-->
      <serviceTab></serviceTab>
    </div>
    <!--    占位盒子-->
    <div class="cont"></div>
    <!--    底部-->
    <div class="bottom_d">
      <div class="logo_d">
        <img src="../assets/img/back.png">
        <p>
          地址：浙江省绍兴市凤林西路300号环宇大厦<br>
          电话：0575-85132445 85336908<br>
          传真：0575-85117404 85336900<br>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #FFFFFF">备案：浙ICP备11004969号-1</a>
        </p>
      </div>
      <div class="wx_logo">
        <img src="../assets/img/logo3.png">
        <p>
          微信公众号：hyjzkj
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import serviceTab from '../components/service/service_tab'
import { exitLogin } from '@/api/api'

export default {
  name: 'service',
  components: {
    serviceTab
  },
  data () {
    return {
      tabPosition: 'left',
      id: ''
    }
  },
  created () {
    this.id = localStorage.getItem('EUJSESSIONID')
  },
  methods: {
    home () {
      this.$router.replace('/')
    },
    // 退出登录
    exitBut () {
      exitLogin({
        method: 'logout'
      }, res => {
        this.$message.info('已注销用户！')
        this.$router.replace('/login/login')
        this.$cookies.remove('token', 'EUJSESSIONID=egCR0M2aKCuk46H9fucdWyPBsqeZTFGnU%2Fylt4meBeE%3D')
        localStorage.removeItem('EUJSESSIONID')
      })
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.home {
  cursor: pointer;
}

/*全部*/
.all {
  position: relative;
  width: 100%;
  height: 1502px;
  margin: 0 auto;
  background-color: #eeeeee;
}

/*顶部title*/
.topTitle {
  width: 100%;
  height: 360px;
  background-color: #ffffff;
  background-image: url("../assets/img/service.png");
  background-size: 100% 100%;
}

.title {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 90px;
  background-color: black;
  opacity: 0.68;
}

.imgLogo > img {
  width: 359px;
  height: 54px;
  padding-top: 10px;
}

.imgLogo >p{
  color: #FFFFFF;
  line-height: 10px;
  padding-left: 67px;
  letter-spacing: 1px;
  font-size: 23.2px;
  cursor: pointer;
  font-weight: bold;
}

.tab_but {
  display: flex;
  width: 600px;
  height: 90px;
  color: #ffffff;
  padding-top: 20px;
  text-align: center;
}

.but_tab {
  font-size: 16px;
  width: 220px;
  height: 40px;
  line-height: 40px;
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.but_tab_Service {
  font-size: 16px;
  width: 220px;
  height: 40px;
  color: #10539B;
  background-color: white;
  line-height: 40px;
  cursor: pointer;
  text-decoration: none;
}

.but_tab:hover {
  width: 220px;
  height: 40px;
  color: #10539B;
  background-color: white;
  cursor: pointer;
}

/*tabs切换*/
.tabs {
  display: flex;
  justify-content: space-around;
  width: 85%;
  height: 850px;
  background-color: #10539B;
  margin: 150px auto;
}

/*中间占位*/
.cont {
  position: relative;
  /*background-color: #10539B;*/
  z-index: -100;
  width: 100%;
  height: 800px;
}

/*底部*/
.bottom_d {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 360px;
  background-color: #191919;
  text-align: left;
  margin: 0px auto;
}

.logo_d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 250px;
  margin-top: 90px;
  color: white;
  line-height: 30px;
}

.logo_d > img {
  width: 300px;
  height: 90px;
  margin-left: -28px;
  margin-top: 38px;
}

.wx_logo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 190px;
  /*background-color: #554ace;*/
  margin-top: 90px;
  color: white;
  line-height: 30px;
}

.wx_logo > img {
  width: 142px;
  height: 142px;
}

.content_text .el-checkbox__label {
  padding-left: 80px;
}

.all_mesg > span {
  padding-left: 40px;
}

.div_gt > p {
  line-height: 23px;
  margin-left: 15px;
  color: #333333;
  font-size: 14px;
}

.ser-button {
  width: 120px;
  height: 42px;
  color: #FFFFFF;
  background-color: #1789e5;
  margin-left: 10px;
  border-radius: 30px;
  border: 0px;
  cursor: pointer;
  font-size: 17px;
  font-family: "Microsoft YaHei",微软雅黑;
}
</style>
