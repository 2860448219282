<template>
  <div id="sign">
    <div v-show="bgSign" class="tableSign">
      <el-table
        :data="tableData"
        :header-cell-style="tableHeaderColor"
        class="el-table"
        style="width: 100%;">
        <el-table-column
          label="项目名称"
          prop="projectName"
          width="150">
        </el-table-column>
        <el-table-column
          label="招标名称"
          prop="name"
          width="150">
        </el-table-column>
        <el-table-column
          label="报名人"
          prop="memberName"
          width="120">
        </el-table-column>
        <el-table-column
          label="报名日期"
          prop="createDate"
          width="120">
        </el-table-column>
        <el-table-column
          label="开标时间"
          prop="endDate"
          width="150">
        </el-table-column>
        <el-table-column
          label="当前状态"
          prop="state"
          width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.state === 'pending'">进行中</span>
            <span v-else-if="scope.row.state === 'done'">已结束</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
        >
          <template slot-scope="scope">
            <div class="Modify" @click="ModifySign(scope.$index)">报 价</div>
            <!--              <div class="Modify" v-else-if="applyShow == true" @click="ModifySign(scope.$index)">修改报价</div>-->
          </template>
        </el-table-column>
      </el-table>

      <div class="page1">
        <el-pagination
          :page-count="this.pages"
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next">
        </el-pagination>
      </div>
    </div>
<!--  报价  -->
    <UpdataSign v-if="updateSign" :id="id" @update="updateS"></UpdataSign>
  </div>
</template>

<script>
import { signRecord, updatePrice } from '@/api/api'
import UpdataSign from '@/components/service/UpdataSign'

export default {
  name: 'sign',
  components: {
    UpdataSign
  },
  data () {
    return {
      pages: 0,
      applyShow: false,
      tableData: [],
      updateSign: false,
      bgSign: true,
      state: '',
      page: 1,
      total: 0,
      size: 8,
      id: '',
      formInline: {}
    }
  },
  created () {
    this.SignR()
  },
  methods: {
    updateS () {
      this.updateSign = false
      this.bgSign = true
    },
    // 设置表头行的样式
    tableHeaderColor ({
      rowIndex
    }) {
      return 'background-color:#F1F3F4;color:#333333;font-size:14px;'
    },
    SignR () {
      signRecord({
        method: 'prices',
        state: '',
        page: this.page,
        size: this.size
      }, res => {
        if (res.data.success === false) {
          this.$message.error(res.data.message)
        }
        this.tableData = res.data.data.data
        this.pages = res.data.data.pages
      })
    },
    ModifySign (index) {
      // 传入列表ID对应的值
      this.id = this.tableData[index].id
      localStorage.setItem('signId', this.tableData[index].tenderInfoId)
      updatePrice({
        method: 'priceById',
        id: this.id
      }, res => {
        if (res.data.success === false || res.data.data === '1') {
          this.$message.error(res.data.message)
          return
        }
        this.updateSign = true // 显示列表详情
        this.bgSign = false // 关闭列表
      })
    },
    // 分页
    handleCurrentChange (page) {
      signRecord({
        method: 'prices',
        state: '',
        page: page,
        size: this.size
      }, res => {
        if (res.data.success === false) {
          this.$message.error(res.data.message)
        }
        this.tableData = res.data.data.data
        this.pages = res.data.data.pages
      })
    }
  }
}
</script>

<style scoped>
.Modify {
  width: 66px;
  height: 24px;
  border: 1px solid #10539B;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #10539B;
  cursor: pointer;
}
.tableSign{
  position: relative;
  width: 100%;
  height: 880px;
  display: flex;
  flex-direction: column;
}
.page1 {
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  /*background-color: #EA5D5C;*/
  display: flex;
  justify-content: center;
}
</style>
