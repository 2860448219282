<template>
  <!--  企业注册-->
  <div>
    <el-form :label-position="labelPosition" :model="formLabelAlign" label-width="100px">
      <el-form-item class="el_form" label="新密码 ">
        <el-input v-model="formLabelAlign.pwd" :show-password="true" placeholder="请输入您的新密码"></el-input>
      </el-form-item>
      <el-form-item class="el_form" label="确认新密码 ">
        <el-input v-model="formLabelAlign.newPwd" :show-password="true" placeholder="请确认您的新密码"></el-input>
      </el-form-item>
<!--      <el-form-item class="validation_Code">-->
<!--        <validation @childSuccess="textA"></validation>-->
<!--      </el-form-item>-->
    </el-form>
    <div class="ht">
<!--      <el-checkbox v-model="checked">我同意</el-checkbox>-->
<!--      <span>《环宇集团采招平台须知》</span></div>-->
    <div class="login_d" @click="tep_btn(checked)">修改密码</div>
  </div>
  </div>
</template>

<script>
import axios from 'axios'
import { enterprise } from '@/api/api'
// import validation from '@/components/code/validation'

// const TIME_COUNT = 60 // 验证码倒计时
export default {
  name: 'new_pwd',
  // components: {
  //   validation
  // },
  data () {
    return {
      validationS: false,
      labelPosition: 'right',
      formLabelAlign: {
        phone: '',
        code: '',
        pwd: '',
        newPwd: ''
      },
      code: '',
      show: true,
      timer: null,
      checked: false,
      message: 0,
      date: ''
    }
  },
  created () {
  },
  methods: {
    textA (val) {
      this.validationS = val
    },
    // phone_gz () {
    //   if (!/^1[0-9]{10}$/.test(this.phone)) {
    //     this.phone = ''
    //     this.$message.error('请输入正确的电话号码')
    //   }
    // },
    msg_but (i) {
      if (i === 0) {
        this.tabs[i].msg = true
        this.tabs[1].msg = false
      } else if (i === 1) {
        this.tabs[0].msg = false
        this.tabs[1].msg = true
      }
    },
    // 获取验证码
    // code_time () {
    //   if (this.validationS === false) {
    //     this.$message.error('请先拖动滑块进行验证！')
    //   } else if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.formLabelAlign.phone) || this.formLabelAlign.phone === '') {
    //     this.$message.error('请输入正确的手机号码！')
    //   } else {
    //     if (!this.timer) {
    //       this.code = TIME_COUNT
    //       this.show = false
    //       axios.defaults.headers.token = localStorage.getItem('token')
    //       VtPhone({
    //         method: 'authCode',
    //         phone: this.formLabelAlign.phone
    //       }, res => {
    //         this.$message.success('验证码已发送至您的手机！')
    //         this.message = res.data.data.data
    //       })
    //       this.timer = setInterval(() => {
    //         if (this.code > 0 && this.code <= TIME_COUNT) {
    //           this.code--
    //         } else {
    //           this.show = true
    //           clearInterval(this.timer)
    //           this.timer = null
    //         }
    //       }, 1000)
    //     }
    //   }
    // },
    // 更改密码
    tep_btn (checked) {
      // console.log(checked)
      // 判断是否是黑名单用户
      const blackUser = localStorage.getItem('blackUser')
      if (blackUser === true) {
        this.$message.error('申诉成功后正常使用')
        return
      }
      if (this.formLabelAlign.pwd === '' || this.formLabelAlign.pwd === null) {
        this.$message.error('请输入您的账户密码！')
      } else if (this.formLabelAlign.newPwd === '' || this.formLabelAlign.newPwd === null) {
        this.$message.error('请输入您的确认登录密码！')
      } else if (this.formLabelAlign.newPwd !== this.formLabelAlign.pwd) {
        this.$message.error('登录密码和确认密码不同！')
      } else {
        var date = new Date()
        var year = date.getFullYear() // 月份从0~11，所以加一
        var dateArr = [date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()]
        for (var i = 0; i < dateArr.length; i++) {
          if (dateArr[i] >= 1 && dateArr[i] <= 9) {
            dateArr[i] = '0' + dateArr[i]
          }
        }
        var strDate = year + '-' + dateArr[0] + '-' + dateArr[1] + ' ' + dateArr[2] + ':' + dateArr[3] + ':' + dateArr[4]
        this.date = strDate
        axios.defaults.headers.token = localStorage.getItem('token')
        enterprise({
          method: 'updateLongPwd',
          password: this.formLabelAlign.pwd
        }, res => {
          location.reload()
          localStorage.clear()
          this.$cookies.remove('token', 'EUJSESSIONID=egCR0M2aKCuk46H9fucdWyPBsqeZTFGnU%2Fylt4meBeE%3D')
          localStorage.removeItem('EUJSESSIONID')
          this.$message.success('修改密码成功！')
          this.$router.replace('/login/login')
        })
      }
    }
  }
}
</script>

<style scoped>
/*登录按钮*/
.login_d {
  width: 200px;
  height: 50px;
  background-color: #10539B;
  font-size: 16px;
  text-align: center;
  line-height: 45px;
  color: white;
  margin-left: 20%;
  border-radius: 4px;
  /*margin-top: 200px;*/
  letter-spacing: 2px;
  font-weight: bold;
  cursor: pointer;
}

.code_get {
  position: absolute;
  width: 140px;
  height: 40px;
  text-align: center;
  background-color: #EEEEEE;
  color: #666666;
  line-height: 37px;
  margin-left: -140px;
  cursor: pointer;
}

/*合同*/
.ht {
  width: 240px;
  font-size: 14px;
  color: #0D5091;
  margin: 80px auto;
}

.bm {
  background-color: #f7f7f7;
  text-align: center;
  font-size: 14px;
  color: #666666;
}

.el_form {
  margin: 40px auto;
  width: 400px;
}

.validation_Code {
  width: 62.5%;
  height: 40px;
  /*background-color: #7ac23c;*/
  margin: 0 auto;
}
</style>
