<template>
  <!--    切换tab-->
  <div id="tabs">
    <div class="tab_left">
      <div v-for="(item,index) in tabbar" :key="index" :class="{tabButton:index1==index}"
           class="tab" v-show="index!==5" @click="tabBar(index)"> {{ item.tab }}
      </div>
    </div>
    <!--    我的消息-->
    <div v-if="index1==0" class="tab_right">
      <tag></tag>
    </div>
    <!--    申诉记录-->
    <div v-if="index1==1" class="tab_right">
      <complaint></complaint>
    </div>
    <!--    企业信息维护-->
    <div v-if="index1==2" class="tab_right">
      <enterprise v-if="tabbar[2].tab===`企业信息维护`"></enterprise>
      <personal v-if="tabbar[2].tab===`个人信息维护`"></personal>
    </div>
    <!--    报名记录-->
    <div v-if="index1==3" class="tab_right">
      <sign></sign>
    </div>
    <!--    修改密码-->
    <div v-if="index1==4" class="tab_right">
      <newPwd></newPwd>
    </div>
    <!--    报名详情-->
    <div v-if="index1==5" class="tab_right">
      <UpdataSign></UpdataSign>
    </div>
  </div>
</template>

<script>
import tag from './tag'
import complaint from '@/components/service/complaint'
import enterprise from '@/components/service/enterprise'
import sign from '@/components/service/sign'
import newPwd from '@/components/newPwd/new_pwd'
import UpdataSign from '@/components/service/UpdataSign'
import personal from '@/components/service/personal'
import { getBlacklistByApi } from '@/api/api'
export default {
  name: 'tabs',
  components: {
    tag,
    complaint,
    enterprise,
    sign,
    newPwd,
    UpdataSign,
    personal
  },
  props: {},
  data () {
    return {
      tabbar: [{
        tab: '我的消息'
      }, {
        tab: '申诉记录',
        userBlack: ''
      }, {
        tab: '信息维护'
      }, {
        tab: '报名记录'
      }, {
        tab: '修改密码'
      }, {
        tab: '报名详情',
        show: false
      }],
      index1: 0
    }
  },
  created () {
    this.private()
    this.userBak()
  },
  methods: {
    userBak () {
      // 查询是否是黑名单用户  黑名单用户才展示按钮
      getBlacklistByApi({
        method: 'getBlacklistBy'
      }, res => {
        if (res.data.data === 'no') {
          localStorage.setItem('blackUser', '正常用户')
        } else if (res.data.data === null) {
          localStorage.clear()
          this.$router.replace('/login/login')
        } else if (res.data.data === 'yes') {
          localStorage.setItem('blackUser', '黑名单')
        }
      })
    },
    tabBar (index) {
      this.userBak()
      const user = localStorage.getItem('blackUser')
      if (user === '正常用户') {
        // true就是正常用户
        this.index1 = index
      } else {
        this.$message.error('请申诉账户，成功申诉后可正常使用功能！')
        this.index1 = 1
      }
    },
    private () {
      if (localStorage.getItem('supplyType') === 'company') {
        this.tabbar[2].tab = '企业信息维护'
      } else if (localStorage.getItem('supplyType') === 'person') {
        this.tabbar[2].tab = '个人信息维护'
        if (this.tabbar[2].tab === '个人信息维护') {
        }
      }
    }
  }
}
</script>

<style scoped>
/*外层盒子*/
#tabs {
  display: flex;
  justify-content: space-around;
  width: 1250px;
  height: 860px;
  margin: 150px auto;
}

/*左侧盒子*/
.tab_left {
  width: 200px;
  height: 900px;
  background-color: white;
}

/*右侧盒子*/
.tab_right {
  width: 950px;
  height: 860px;
  background-color: white;
  padding: 20px 20px;
}

/*默认样式*/
.tab {
  height: 60px;
  border-bottom: 1px solid #EEEEEE;
  text-align: center;
  line-height: 60px;
  color: #666666;
  cursor: pointer;
}

/*选中样式*/
.tabButton {
  background: #10539B;
  color: white;
}

</style>
